import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  },
});

const currentUrl = window.location.href.toString().toLocaleLowerCase();

const isCarmax = () => {
  if (!currentUrl.includes("carmax-kos")) {
    instance.defaults.headers.common["Tenant"] =
      "1C8D5905-DF62-4AD1-9730-EAA3FD80919A";
    return true;
  } else {
    instance.defaults.headers.common["Tenant"] =
      "506A16B9-1397-4EB7-BB87-AABFE9E8E560";
    return false;
  }
};

const webControls = {
  isCarmax: isCarmax(),
};

// LOGIN TOKEN
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

async function refreshAccessToken() {
  try {
    const response = await axios.post(`${baseURL}Tokens/refresh`, {
      refreshToken: localStorage.getItem("refreshToken"),
      token: localStorage.getItem("accessToken"),
    });

    localStorage.setItem("accessToken", response.data.token);
    localStorage.setItem("refreshToken", response.data.refreshToken);
  } catch (err) {
    localStorage.clear();
    window.location.reload();
    throw err;
  }
}
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await refreshAccessToken();
        originalRequest.headers.Authorization = `Bearer ${localStorage.getItem(
          "accessToken"
        )}`;
        return instance(originalRequest);
      } catch (err) {
        console.error(err);
        localStorage.clear();
        window.location.reload();
        throw err;
      }
    }
    return Promise.reject(error);
  }
);

// GET TOKEN
async function GetToken(tokenBody) {
  return instance.post("/Tokens/getToken", tokenBody).then((response) => {
    return Promise.resolve(response);
  });
}

// CLIENT AXIOS
async function ClientSearchBar(
  pageNumber = 1,
  name = "",
  status = "",
  createdDate = "",
  pageSize = null
) {
  return instance
    .get(
      `/Client/list?pageNumber=${pageNumber}&searchText=${name}&status=${status}&createdOn=${createdDate}&pageSize=${pageSize}`
    )
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetClientsDetails(clientDetailsId) {
  return instance
    .get(`/Client/getClientDetails?id=${clientDetailsId}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function UpdateClient(updateClientBody) {
  return instance
    .post(`/Client/updateClient`, updateClientBody)
    .then((response) => {
      return Promise.resolve(response);
    });
}

// PRICE LIST AXIOS
async function GetPriceList() {
  return instance.get(`/Pricelist/getPriceList`).then((response) => {
    return Promise.resolve(response);
  });
}

async function EditPriceListAxios(editPriceListBody) {
  return instance
    .post("/Pricelist/updatePricelist", editPriceListBody)
    .then((response) => {
      return Promise.resolve(response);
    });
}

// SERVICE AXIOS
async function ServicesList(pageNumber = 1, name = null, status = null) {
  return instance
    .get(`/Service/list?pageNumber=${pageNumber}&name=${name}&status=${status}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function AddServiceAxios(addServiceBody) {
  return instance
    .post("/Service/addService", addServiceBody)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetServiceDetails(serviceId) {
  return instance
    .get(`/Service/getServiceDetails?id=${serviceId}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetCountries() {
  return instance.get(`/Service/getCountries`).then((response) => {
    return Promise.resolve(response);
  });
}

async function UpdateService(updateServiceBody) {
  return instance
    .post("/Service/updateService", updateServiceBody)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function DeleteService(serviceId) {
  return instance
    .post(`/Service/deleteService?id=${serviceId}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

// CONTACT AXIOS
async function GetContactList(pageNumber = 1) {
  return instance
    .get(`/Contact/list?pageNumber=${pageNumber}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetContactDetails(contactClientId) {
  return instance
    .get(`/Contact/getMessageDetails?id=${contactClientId}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function DeleteComment(contactClientId) {
  return instance
    .post(`/Contact/deleteMessage?id=${contactClientId}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

// USERS AXIOS

async function GetCurrentUser() {
  return instance.get(`/Tokens/getCurrentUser`).then((response) => {
    return Promise.resolve(response);
  });
}

async function UserChangePassword(userChangePasswordBody) {
  return instance
    .post(`/User/change-password`, userChangePasswordBody)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function UserForgotPassword(email) {
  return instance
    .post(`/User/forgot-password?email=${email}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function ResetUserPassword(resetPasswordBody) {
  return instance
    .post("/User/reset-user-password", resetPasswordBody)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetUsers(pageNumber = 1, name = null, status = null) {
  return instance
    .get(`/User/list?pageNumber=${pageNumber}&name=${name}&status=${status}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function AddUserAxios(addUserBody) {
  return instance.post(`/User/addUser`, addUserBody).then((response) => {
    return Promise.resolve(response);
  });
}

async function GetUserDetails(userId) {
  return instance.get(`/User/getUserDetails?id=${userId}`).then((response) => {
    return Promise.resolve(response);
  });
}

async function UpdateUserDetails(updatedUserDetails) {
  return instance
    .post(`/User/updateProfile`, updatedUserDetails)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function DeleteUser(userId) {
  return instance.post(`/User/deleteUser?id=${userId}`).then((response) => {
    return Promise.resolve(response);
  });
}

async function CheckIfUserEmailExist(email) {
  return instance
    .get(`/User/checkIfUserEmailExists?email=${email}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

// APROVE & REFUSE AXIOS
async function AproveClient(clientId, maxOpenBids) {
  return instance
    .post(`/Client/aproveClient?id=${clientId}&maxOpenBids=${maxOpenBids}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function RefuseClient(clientId, message) {
  return instance
    .post(`/Client/refuseClient?id=${clientId}&message=${message}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

// ARVAL API
async function GetAppServices() {
  return instance.get(`/AppSettings/getAppServices`).then((response) => {
    return Promise.resolve(response);
  });
}

async function GetAppServiceDetails(name) {
  return instance
    .get(`/AppSettings/getAppSettingDetails?name=${name}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function UpdateAppService(updateAppServiceBody, name) {
  const body = {
    name: name,
    appSettings: updateAppServiceBody,
  };
  return instance
    .post(`/AppSettings/updateAppSettingValues`, body)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetCarsByStatusId(
  id,
  currentPage = 1,
  clientName = "",
  carName = "",
  fromDate = "",
  toDate = ""
) {
  return instance
    .get(
      `/ActiveAuction/ActiveAuctionCar/ByStatusId/CarListForAdmin?_statusId=${id}&_pageNumber=${currentPage}&_clientName=${clientName}&_carName=${carName}&_fromDate=${fromDate}&_toDate=${toDate}`
    )
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetClientsBidHistoryFromAdmin(
  id,
  currentPage = 1,
  clientName = "",
  carName = "",
  fromDate = "",
  toDate = ""
) {
  return instance
    .get(
      `ActiveAuction/getClientsBidHistoryFromAdmin?_statusId=${id}&_pageNumber=${currentPage}&_clientName=${clientName}&_carName=${carName}&_fromDate=${fromDate}&_toDate=${toDate}`
    )
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetBidHistoryForCar(carId) {
  return instance
    .get(`/ActiveAuction/getBidHistoryForCar?carId=${carId}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetInvoiceByCarId(carAuctionId) {
  return instance
    .get(`ClientInvoices/ClientInvoiceForAdmin?carAuctionId=${carAuctionId}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function ActiveAuctionCarDetails(id) {
  return instance
    .get(`/ActiveAuction/ActiveAuctionCarDetails?id=${id}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetConfirmedReceivedCarsByClientId(statusId, clientId) {
  return instance
    .get(
      `ActiveAuction/ClientCarList/ByStatusId/ClientCarListForAdmin?StatusId=${statusId}&ClientId=${clientId}`
    )
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetDownloadInvoicePdfData(id) {
  return instance
    .get(`ClientInvoices/ClientInvoiceToPDF?id=${id}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function UpdateCarStatusId(carId) {
  return instance
    .post(
      `/ActiveAuction/Update/CarAuctionStatusId/ToReceived?CarAuctionId=${carId}`
    )
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetInvoicesList(currentPage) {
  return instance
    .get(`/ClientInvoices/list?PageNumber=${currentPage}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function InvoiceConfirmPaid(invoiceId, invoiceType) {
  const body = {
    clientInvoiceId: parseInt(invoiceId),
    invoiceType: invoiceType || null,
  };
  return instance
    .post(`/ClientInvoices/Add/InvoiceConfirmPaid`, body)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetDashboardHeaderData() {
  return instance.get(`/Dashboard/GetGeneralDashboard`).then((response) => {
    return Promise.resolve(response);
  });
}

async function GetTotalSalesByQuantity() {
  return instance
    .get(`/Dashboard/GetMonthyTotalSales/QuantityList`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetTotalSalesByPrice() {
  return instance
    .get(`/Dashboard/GetMonthyTotalSales/PriceList`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

function DownloadInvoice(id, carId) {
  window.location.href = `${baseURL}ClientInvoices/ClientInvoiceToPDF?id=${id}&CarId=${carId}`;
}

async function DeleteClient(clientId) {
  return instance
    .post(`/Client/deleteClient?id=${clientId}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetClientInvoiceAndCarDetailsForAdmin(carAuctionId) {
  return instance
    .get(
      `ClientInvoices/ClientInvoiceAndCarDetailsForAdmin?carAuctionId=${carAuctionId}`
    )
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function CreateInvoice(
  clientId,
  createdInvoiceType,
  invoiceLines,
  selectedInvoice,
  carId
) {
  const updatedInvoiceLines = invoiceLines?.map(({ id, ...rest }) => rest);
  const body = {
    clientInvoiceId: selectedInvoice || null,
    carAuctionId: carId || null,
    invoiceType: createdInvoiceType,
    clientId: clientId,
    lines: updatedInvoiceLines,
  };
  return instance.post(`/Invoices/Add/Invoice`, body).then((response) => {
    return Promise.resolve(response);
  });
}

async function GetInvoices(pageNumber = 1, searchValues, invoiceType = 1, customInvoiceType = 3) {
  const invoiceNo = searchValues.invoiceNo || "";
  const companyName = searchValues.companyName || "";
  const invoiceDate = searchValues.invoiceDate || "";
  const confirmPaidDate = searchValues.confirmPaidDate || "";
  const totalAmount = searchValues.totalAmount || "";
  return instance
    .get(
      `Invoices/list?pageNumber=${pageNumber}&invoiceNo=${invoiceNo}&companyName=${companyName}&invoiceDate=${invoiceDate}&totalAmount=${totalAmount}&confirmPaidDate=${confirmPaidDate}&invoiceType=${invoiceType}&customInvoiceType=${customInvoiceType}`
    )
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetActiveInvoiceServices() {
  return instance
    .get(`DefInvoiceService/list/ByActiveStatus`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

function DownloadInvoice2(id, invoiceType = 1) {
  window.location.href = `${baseURL}Invoices/InvoiceToPDF?id=${id}&invoiceType=${invoiceType}`;
}

async function GetInvoiceServices() {
  return instance.get(`DefInvoiceService/list`).then((response) => {
    return Promise.resolve(response);
  });
}

async function DeleteInvoice(invoiceId) {
  return instance
    .post(`/Invoices/Delete/Invoice?id=${invoiceId}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function DeleteInvoiceService(serviceId) {
  return instance
    .post(`DefInvoiceService/Delete/DefInvoiceService?id=${serviceId}`)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function CreateDefServiceInvoice(service) {
  const body = {
    description: service.description,
    priceWithoutVAT: service.priceWithoutVAT,
    vatRate: service.vatRate,
    priceWithVAT: service.priceWithVAT,
    statusId: service.statusId,
    invoiceType: service.invoiceType,
  };
  return instance
    .post(`DefInvoiceService/Add/DefInvoiceService`, body)
    .then((response) => {
      return Promise.resolve(response);
    });
}

async function GetServiceInvoiceById(id) {
  return instance.get(`DefInvoiceService/Details?id=${id}`).then((response) => {
    return Promise.resolve(response);
  });
}

async function EditDefServiceInvoice(service, id) {
  const body = {
    id: service?.id,
    description: service.description,
    priceWithoutVAT: service.priceWithoutVAT,
    vatRate: service.vatRate,
    priceWithVAT: service.priceWithVAT,
    statusId: service.statusId,
    invoiceType: service.invoiceType
  };
  return instance
    .post(`DefInvoiceService/Update/DefInvoiceService`, body)
    .then((response) => {
      return Promise.resolve(response);
    });
}

export {
  GetBidHistoryForCar,
  GetClientsBidHistoryFromAdmin,
  GetToken,
  GetCurrentUser,
  UserChangePassword,
  UserForgotPassword,
  ResetUserPassword,
  GetClientsDetails,
  ClientSearchBar,
  UpdateClient,
  ServicesList,
  AddServiceAxios,
  GetServiceDetails,
  GetCountries,
  UpdateService,
  DeleteService,
  GetPriceList,
  EditPriceListAxios,
  GetContactList,
  GetContactDetails,
  DeleteComment,
  GetUsers,
  AddUserAxios,
  GetUserDetails,
  UpdateUserDetails,
  DeleteUser,
  CheckIfUserEmailExist,
  AproveClient,
  RefuseClient,
  GetAppServices,
  GetAppServiceDetails,
  UpdateAppService,
  GetCarsByStatusId,
  GetInvoiceByCarId,
  ActiveAuctionCarDetails,
  GetConfirmedReceivedCarsByClientId,
  GetDownloadInvoicePdfData,
  UpdateCarStatusId,
  GetInvoicesList,
  InvoiceConfirmPaid,
  GetDashboardHeaderData,
  GetTotalSalesByQuantity,
  GetTotalSalesByPrice,
  DownloadInvoice,
  DeleteClient,
  webControls,
  GetClientInvoiceAndCarDetailsForAdmin,
  CreateInvoice,
  GetInvoices,
  GetActiveInvoiceServices,
  DownloadInvoice2,
  GetInvoiceServices,
  DeleteInvoice,
  DeleteInvoiceService,
  CreateDefServiceInvoice,
  GetServiceInvoiceById,
  EditDefServiceInvoice,
};
